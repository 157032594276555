import React, { useState } from 'react';
import { PayPalScriptProvider, PayPalButtons } from '@paypal/react-paypal-js';
import 'react-phone-number-input/style.css';
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';

import Base from '../components/Base.js';
import styles from './Tickets.module.css';

// TODO refactor
async function createOrder(data, donationInfo) {
    // Order is created on the server and the order id is returned
    return await fetch('/api/tickets/create', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(donationInfo),
    });
};

async function onApprove(data) {
    // Order is captured on the server and the response is returned to the browser
    return await fetch(`/api/tickets/${data.orderID}/capture`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: ''
    })
};

const PAYPAL_CLIENT_ID = process.env.REACT_APP_PAYPAL_CLIENT_ID;
const BOOKING_LINK = 'https://www.hilton.com/en/book/reservation/deeplink/?ctyhocn=DDHDHHF&groupCode=SUDOKU&arrivaldate=2025-04-03&departuredate=2025-04-06&cid=OM,WW,HILTONLINK,EN,DirectLink&fromId=HILTONLINKDIRECT';
const EMAIL_REGEX = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/i;

export default function Tickets()
{
    const [state, setState] = useState('browsing'); // browsing, terms, checkout, submitted, approved, error
    const [error, setError] = useState(null);
    const [tickets, setTickets] = useState({
        'adult': 0,
        'child': 0,
        'student': 0,
        'unwaged': 0,
    });
    const [info, setInfo] = useState({
        'names': [''],
        'phone': '',
        'email': '',
        'dietaryRestrictions': false,
        'dietaryRestrictionsDetails': '',
        'accomodations': false,
        'accomodationsDetails': '',
        'volunteer': false,
        'dinner': false,
    });
    const [discount, setDiscount] = useState('');

    const ticketCosts = {
        'adult': 100,
        'child': 10,
        'student': 10,
        'unwaged': 10,
    }

    function totalCost()
    {
        let cost = 0;
        Object.keys(tickets).forEach(type => {
            cost += tickets[type] * ticketCosts[type];
        });
        if (discount.toUpperCase() === 'SPONSOR')
            cost /= 2;
        return cost;
    }

    function checkout()
    {
        let err = [];
        const ticketCount = Object.values(tickets).reduce((a, b) => a + b, 0);
        if (ticketCount <= 0)
            err.push('Must purchase at least one ticket.');
        let missingNames = 0;
        info.names.forEach(name => {
            if (name === '')
                missingNames++;
        });
        if (missingNames > 0)
            err.push('Must fill out the required field: Name on Badge');
        if (info.dietaryRestrictions && info.dietaryRestrictionsDetails === '')
            err.push('Must fill out the required field: Dietary Restrictions');
        if (info.accomodations && info.accomodationsDetails === '')
            err.push('Must fill out the required field: Accomodations');
        if (!isValidPhoneNumber(info.phone))
            err.push('Must enter a valid phone number.');
        if (!info.email.match(EMAIL_REGEX))
            err.push('Must enter a valid email address.');
        if (discount.toUpperCase() === 'SPONSOR' && ticketCount > 3)
            err.push('SPONSOR discount can be used for a maximum of three tickets.');
        if (!err.length)
            setState('terms');
        else
            setError(err.join('\n'));
    }

    const userCreateOrder = async (data, actions) => {
        const res = await createOrder(data, {
            tickets,
            info,
            discount,
            amount: totalCost(),
        });
        if (res.status === 201) {
            return await res.json().then((order) => order.id);
        }
        const resJson = await res.json();
        setState('error');
        setError(resJson.error);
    }

    const userApprove = async (data, actions) => {
        setState('submitted');
        const res = await onApprove(data);
        const resJson = await res.json();
        if (res.status === 201) {
            setState('approved');
            return resJson;
        }
        setState('error');
        setError(resJson.message);
    }

    function ticketCounter(id)
    {
        function setTicketCount(value) {
            if (value < 0)
                return;
            const newTickets = { ...tickets, [id]: value };
            setTickets(newTickets);
            let ticketCount = Object.values(newTickets).reduce((a, b) => a + b, 0);
            if (ticketCount < 1)
                ticketCount = 1;
            let names = info.names;
            if (names.length > ticketCount)
                names = names.slice(0, ticketCount);
            while (names.length < ticketCount)
                names.push('');
            setInfo({ ...info, names });
        }

        return <div className={styles.counter}>
            <div className={styles.counterPlusMinus} onClick={() => {
                setTicketCount(tickets[id] + 1);
            }}><div>+</div></div>
            <input type="text" className={styles.counterText} value={tickets[id]} onChange={e => {
                if (!isNaN(e.target.value) && Number.isInteger(Number(e.target.value)))
                    setTicketCount(Number(e.target.value));
            }}></input>
            <div className={styles.counterPlusMinus} onClick={() => {
                setTicketCount(tickets[id] - 1);
            }}><div>-</div></div>
        </div>
    }

    function infoInput(type, id) {
        if (type === 'textarea')
            return <textarea className={styles.textarea} rows="4" value={info[id]} onChange={(e) => {
                setInfo({ ...info, [id]: e.target.value });
            }} />
        return <input type={type} value={info[id]} onChange={(e) => {
            setInfo({ ...info, [id]: e.target.value });
        }} />
    }

    function yesNoInput(id) {
        return <>
            <div style={{ display: 'inline-block' }} onClick={() => {
                setInfo({ ...info, [id]: true });
            }}>
                <input type="radio" checked={info[id]} readOnly />
                Yes
            </div>
            <div style={{ display: 'inline-block', marginLeft: '0.5em' }} onClick={() => {
                setInfo({ ...info, [id]: false });
            }}>
                <input type="radio" checked={!info[id]} readOnly />
                No
            </div>
        </>
    }

    let display;
    switch (state)
    {
        case 'browsing':
            display = <div className={styles.container}>
                <center style={{ fontSize: '1.5em', fontWeight: 'bold', marginBottom: '1em' }}>Purchase Tickets</center>
                <div>
                    {ticketCounter('adult')} Adults <span style={{ fontSize: '0.8em' }}>ages 18+</span> (${ticketCosts.adult}) <div style={{ display: 'inline-block', fontSize: '0.8em'}}>special early bird price!</div><br/>
                    {ticketCounter('child')} Children <span style={{ fontSize: '0.8em' }}>ages 12-17</span> (${ticketCosts.child})<br/>
                    {ticketCounter('student')} Students (${ticketCosts.student})<br/>
                    {ticketCounter('unwaged')} Unwaged (${ticketCosts.unwaged})
                </div>
                <div>
                    {tickets.child > 0 ? <>&#10071; Children ages 12-15 must be accompanied at all times by a guardian aged 18+</> : null}
                </div>
                <div>
                    <div className={styles.label}>{info.names.length > 1 ? 'Names you want displayed on your badges' : 'Name you want displayed on your badge'}:</div>
                    {info.names.map((name, i) => <div key={i}>
                        <input type="text" value={info.names[i]} onChange={(e) => {
                            info.names[i] = e.target.value;
                            setInfo({ ...info });
                        }} />
                        <br />
                    </div>)}
                    <div className={styles.label}>Phone:</div>
                    <PhoneInput
                        placeholder="123-456-7890"
                        defaultCountry="US"
                        value={info.phone}
                        onChange={(phone) => { setInfo({ ...info, phone }); }}
                        style={{ width: '177px' }}
                    />
                    <div className={styles.label}>Email:</div>
                    <input type="text"
                        value={info.email}
                        onChange={(e) => { setInfo({ ...info, email: e.target.value }); }}
                    />
                    <div className={styles.label}>Do you have any dietary restrictions we should know about?</div>
                    {yesNoInput('dietaryRestrictions')}
                    {info.dietaryRestrictions ? <>
                        <div className={styles.subLabel}>Please specify:</div>
                        {infoInput('textarea', 'dietaryRestrictionsDetails')}
                    </> : null}
                    <div className={styles.label}>Will you need any accommodations in order to participate fully in the event?</div>
                    {yesNoInput('accomodations')}
                    {info.accomodations ? <>
                        <div className={styles.subLabel}>Please specify:</div>
                        {infoInput('textarea', 'accomodationsDetails')}
                        <div style={{ fontSize: '0.8em' }}>We will do our best to provide for your needs, but we cannot guarantee any specific accomodations will be available. If you have questions or concerns, reach out to the planning team at <a href="mailto:info@sudokucon.com">info@sudokucon.com</a> or contact us on our <a href="https://discord.gg/WGqaazd7Yb" rel="noreferrer" target="_blank">Discord server</a>.</div>
                    </> : null}
                    <div className={styles.label}>Are you interested in volunteering?</div>
                    {yesNoInput('volunteer')}
                    <div className={styles.label}>Are you interested in attending the Saturday night communal dinner? Note that you would pay for your own food.</div>
                    {yesNoInput('dinner')}
                    <br/>
                    <input type="text"
                        value={discount}
                        onChange={(e) => { setDiscount(e.target.value); }}
                        style={{ margin: '1em 0.5em 0 0' }}
                    />
                    Discount code
                </div>
                <br/>
                <button onClick={checkout}>Checkout</button>
                {error ? <div className={styles.formErrors}>
                    Please resolve the following errors before continuing:
                    <ul>{error.split('\n').map(err => <li key={err}>{err}</li>)}</ul>
                </div> : null}
            </div>;
            break;
        case 'terms':
                        /*If the Hilton Boston Dedham Hotel is unable to provide a sleeping room to an Event attendee holding a confirmed
                        reservation, Hotel will provide to each attendee as liquidated damages for the nights the attendee is not
                        accommodated:
                        1. Arrangements for accommodations at a comparable nearby hotel and payment for one night of
                        accommodations.
                        2. Free transportation for attendee to and from the Hilton Boston Dedham Hotel.
                        3. Priority reservations for the first available room at the Hilton Boston Dedham Hotel the next night, and
                        4. One long distance phone call to notify of change of location.
                        */
            display =
                <div className={styles.container}>
                    <h2 style={{ marginTop: 0 }}>Terms and Conditions</h2>
                    <div className={styles.terms}>
                        <h3>Underage Attendance</h3>
                        <p>You will ensure the information you have provided on this page is accurate.</p>
                        <p>Children older than 2 and younger than 12 are not allowed to attend SudokuCon. Anyone attending under the age of 16 must be accompanied by an adult ticketholder at all times. Guardians are entirely responsible for the safety and actions of the minors they are accompanying. SudokuCon will not provide personnel or facilities to look after minors.</p>

                        <h3>Conduct</h3>
                        <p>Whilst attending SudokuCon, you must comply with all applicable law and all instructions from hotel and SudokuCon staff members. You are responsible for managing your own safety. SudokuCon shall not be liable for any loss or damage suffered by you.</p>
                        <p>Absolutely no solicitation is allowed other than by officially sanctioned SudokuCon vendors. This restriction applies to any circumstance that can reasonably be considered to be at or during SudokuCon, which includes, but is not limited to: the scheduled sessions, the side activities, social gatherings surrounding SudokuCon, or in the lobby or other public spaces near the event. The determination of what constitutes solicitation is at the discretion of SudokuCon staff.</p>
                        <p>In the event that damage to SudokuCon or hotel property occurs as a result of your actions, whether due to negligence or misconduct, you are responsible for assuming all liability and expense.</p>

                        <h3>Food and Alcohol</h3>
                        <p>SudokuCon 2025 is hosted by the Hilton Boston Dedham, and guests are obligated to adhere to their policies.</p>
                        <p>No food or beverage supplied by SudokuCon or the Hilton may be removed from the premises. No liquor may be brought onto the Hilton property from the outside. You may not have more than one drink in front of you at any one time, excluding wine with dinner. You may not remove/transport liquor from the function room at any time.</p>

                        <h3>Photography</h3>
                        <p>We may, at our discretion, choose to photograph, film, broadcast, or record the event. You grant us an irrevocable licence to use your voice, image, likeness, and any contribution made by you at or to the event in any and all media (whether now known or hereinafter invented) throughout the world and in perpetuity. You must notify us at least 48 hours prior to the event if you do not wish your voice, image, likeness, and/or contributions to be used in such a manner. Only notices submitted to info@sudokucon.com will be considered.</p>
                        <p>You agree to obtain the consent of all parties whose voice, image, likeness, or contributions may appear (whether visually, auditorily, or otherwise) in any media you record and/or broadcast at SudokuCon.</p>

                        <h3>Cancellation and refunds</h3>
                        <p>All ticket sales are final and non-refundable. The ticket cost covers only your entry for the duration of SudokuCon 2025 and anything that is distributed to attendees as part of the event. You are on your own for all other costs, including but not limited to: travel, accomodation, insurance, meals (except those included in the program), tax, and incidentals.</p>
                        <p>We may make changes to the content of SudokuCon at any time and without warning, including but not limited to the schedule, timing, activities, session hosts, special guests, and prepared food. At no point shall such changes entitle you to substitution or compensation for prior expectations. You agree to not hold SudokuCon liable for any indirect, economic, special, or consequential loss or damages of any kind that are sustained as a result of such changes.</p>
                        <p>We reserve the right to revoke your ticket, remove you from the event, and refuse re-entry, without warning and without refund, if you fail to comply with the Terms and Conditions. You shall indemnify us from and against all claims, damage, losses, costs, expenses, demands or liabilities arising out of or in connection with any breach by you of the Terms and Conditions.</p>
                        <p>If any provision of the Terms and Conditions is found to be unenforceable by law, the rest of the provisions shall still remain in effect.</p>

                        <h3>Privacy policy</h3>
                        <p>This website does not use cookies or collect any personal information from users. We do not share information about individual users with third parties.</p>
                        <p>If you donate to SudokuCon via PayPal, we may be able to see your name, address, phone number, email address, and other personal information you have provided to PayPal. We use this information only for the purpose of collecting payments via PayPal and providing donation rewards. Please review PayPal's <a href="https://www.paypal.com/us/legalhub/privacy-full" target="_blank" rel="noreferrer">Privacy Policy</a> and <a href="https://www.paypal.com/us/webapps/mpp/ua/acceptableuse-full?_ga=1.130764367.1787085997.1696273619" target="_blank" rel="noreferrer">Acceptible Use Policy</a> for more details.</p>
                    </div>
                    <button onClick={() => { setState('checkout'); }}>I agree to the terms and conditions.</button>
                </div>;
            break;
        case 'checkout':
            display = <div className={styles.container}>
                Order summary:
                {info.names.map((name, i) => <div key={i}>Name: {name}</div>)}
                <div>Phone: {info.phone}</div>
                <div>Email: {info.email}</div>
                {Object.keys(tickets).map(type => tickets[type] ? <div key={type}>
                    <div style={{ display: 'inline-block', width: '6em' }}>
                        ${(tickets[type] * ticketCosts[type]).toFixed(2)}
                    </div>
                    <div style={{ display: 'inline-block' }}>
                        {tickets[type]}x {type} ticket
                    </div>
                </div> : null)}
                {discount.toUpperCase() === 'SPONSOR' ? <>
                    <hr style={{ width: '15em', marginLeft: 0 }} />
                    <div style={{ display: 'inline-block', width: '6em' }}>
                        -${totalCost().toFixed(2)}
                    </div>
                    <div style={{ display: 'inline-block' }}>
                        SPONSOR discount applied
                    </div>
                </> : null }
                <hr style={{ width: '15em', marginLeft: 0 }} />
                <div>
                    <div style={{ display: 'inline-block', width: '6em' }}>
                        ${totalCost().toFixed(2)}
                    </div>
                    <div style={{ display: 'inline-block' }}>
                        Total
                    </div>
                </div>
                <button className={styles.backButton} onClick={() => { setState('browsing'); }}>Go Back</button>
                <div className={styles.paypalButtons}>
                    <PayPalButtons
                        createOrder={userCreateOrder}
                        options={{ shippingPreference: 'NO_SHIPPING' }}
                        onApprove={userApprove}
                        onSuccess={(details) => { console.log('success') }}
                        catchError={(err) => { console.log('catcherror') }}
                        onError={(err) => { setState('error'); setError(err.message); }}
                    />
                </div>
            </div>
            break;
        case 'submitted':
            display = <div className={styles.container}>Waiting for server...</div>
            break;
        case 'approved':
            display = <div className={styles.successContainer}>
                Your attendance has been recorded, and your ticket will show up in your email within the next week. We look forward to seeing you there! You can find useful information on getting to the hotel on our <a href="/transit" target="_blank">Transit Page</a>, and don't forget to <a href={BOOKING_LINK} target="_blank" rel="noreferrer">book your room</a>!
            </div>
            break;
        default:
            // TODO If the problem persists, RegFox
            display = <div className={styles.errorContainer}>
                An error occured: {error ? error : 'Unknown error.'}
                <br />
                If the problem persists, try <a href="https://sudokucon.regfox.com/sudokucon-2025" style={{ textDecoration: 'underline' }} target="_blank" rel="noreferrer">registering on our backup platform</a>.</div>
            break;
    }

    return <PayPalScriptProvider options={{ clientId: PAYPAL_CLIENT_ID }}>
        <Base>
            {display}
        </Base>
    </PayPalScriptProvider>;
}
