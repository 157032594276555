import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import './index.css';
import About from './pages/About';
import Donate from './pages/Donate';
import DonationBar from './pages/DonationBar';
import Donations from './pages/Donations';
//import DonateBits from './pages/DonateBits';
import DonateCard from './pages/DonateCard';
import Livestream from './pages/Livestream';
import Policy from './pages/Policy';
import Puzzles from './pages/Puzzles';
import Schedule from './pages/Schedule';
import Tickets from './pages/Tickets';
import Transit from './pages/Transit';
import Home from './pages/Home';

ReactDOM.render(
    <React.StrictMode>
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/about" element={<About />} />
                <Route path="/donationbar" element={<DonationBar />} />
                <Route path="/donations" element={<Donations />} />
                <Route path="/donate/">
                    <Route index element={<Donate />} />
                    <Route path="bits" element={<Donate />} />
                    <Route path="card" element={<DonateCard />} />
                </Route>
                <Route path="/policy" element={<Policy />} />
                <Route path="/puzzles" element={<Puzzles />} />
                <Route path="/schedule" element={<Schedule />} />
                <Route path="/stream" element={<Livestream />} />
                <Route path="/survey" Component={() => {
                    window.location.replace('https://forms.gle/vK8Q8jopGqz1LJE37');
                }} />
                <Route path="/tickets" element={<Tickets /> } />
                <Route path="/transit" element={<Transit />} />
                <Route path="*" element={<div>404</div> } />
            </Routes>
        </BrowserRouter>
    </React.StrictMode>,
    document.getElementById('root')
);
