import { useState, useEffect } from 'react';

const { io } = require('socket.io-client');

function timeCompare(a, b) {
    const aTime = new Date(a.time);
    const bTime = new Date(b.time)
    if (aTime > bTime)
        return 1;
    if (aTime < bTime)
        return -1;
    return 0;
}

function DonationTracker(props) {
    const [socket, setSocket] = useState(null);

    useEffect(() => {
        fetch(
            '/api/donations',
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ after: new Date('2024-11-18') }),
            }
        ).then(async res => {
            if (res.status == 200) {
                props.setDonations(await res.json());
            }
        });
    }, []);

    useEffect(() => {
        fetch(
            '/api/tickets',
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ after: new Date('2024-11-18') }),
            }
        ).then(async res => {
            if (res.status == 200) {
                props.setTickets(await res.json());
            }
        });
    }, []);

    useEffect(() => {
        if (!socket) {
            const s = io();
            s.on('connect', () => {
                console.log('Socket connected', s.id);
            });
            s.on('connect_error', (error) => {
                if (!s.active) {
                    console.log('Temporary connection failure; attempting reconnect...');
                } else {
                    console.log('Connection denied by server.');
                    console.log(error.message);
                }
            });
            s.on('disconnect', () => {
                console.log(s.id);
            });
            s.on('donation', donation => {
                props.setDonations(donos => {
                    const newDonos = [...donos, donation];
                    newDonos.sort(timeCompare);
                    return newDonos;
                });
            });
            s.on('ticket', ticket => {
                props.setTickets(tickets => { return [...tickets, ticket] });
            });
            setSocket(s);
        }
    }, [socket]);

    return null;
}

export default DonationTracker;
