import { useState } from 'react';

import DonationTracker from '../components/DonationTracker';
import styles from './DonationBar.module.css';

function DonationBar() {
    const [donations, setDonations] = useState([]);
    const [tickets, setTickets] = useState([]);

    let lastDonationStr = 'No donations yet';
    if (donations.length) {
        const lastDonation = donations[donations.length - 1];
        lastDonationStr = `Last donation: $${lastDonation.amount.toFixed(2)} by ${lastDonation.name ? lastDonation.name : 'Anonymous'}`
    }

    const raised = donations.reduce((sum, donation) => sum + donation.amount, 0);

    const ticketCount = tickets.reduce((a, b) => a + b.count, 0);
    const ticketAmount = tickets.reduce((a, b) => a + b.amount, 0);
    const goal = 100;
    const progress = (goal > 0) ? (100 * ticketCount / goal) + '%' : 100;

    console.log('tickets', tickets);
    return <div className={styles.donationbar}>
        <DonationTracker setDonations={setDonations} setTickets={setTickets} />
        <div className='background' style={{ position: 'absolute', width: '100%', height: '100%', background: '#EBC4FF', zIndex: -2 }} />
        <div className='progress' style={{ position: 'absolute', width: progress, height: '100%', background: '#FE7BFD', zIndex: -1 }} />
        <div className='container' style={{ display: 'flex', alignItems: 'center', height: '100%', padding: '0 20px' }}>
            <div className='lastDonation' style={{ fontSize: '1.8em' }}>{lastDonationStr}</div>
            <div className='totalRaised' style={{ fontSize: '2.2em', fontWeight: 'bold', marginLeft: 'auto', marginRight: 'auto' }}>Tickets sold: {ticketCount} / {goal}</div>
            <div className='totalRaised' style={{ fontSize: '2.2em', fontWeight: 'bold', marginLeft: 'auto', marginRight: 'auto' }}>Total raised: ${(raised + ticketAmount).toFixed(2)}</div>
            <div className='goal' style={{ fontSize: '1.5em', textAlign: 'center' }}>
                <div>Type !donate for information</div>
                <div>on how to donate</div>
            </div>
        </div>
    </div>
}

export default DonationBar;
